import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";

import iconPdf from "../assets/icons/pdf.svg";
import photoHero from "../assets/images/heroHome.jpg";
import LazyIframe from "../components/common/iframe/lazyIframe";
import Layout from "../components/common/layout/layout";
// import { Contact } from "../components/Forms/Contact/Contact";
// import withForm from "../components/Forms/withForm";
import Hero from "../components/Hero/Hero";
import Map from "../components/Map/Map";
import HomeMarkupSchema from "../components/SEO/HomeMarkupSchema";
import SEO from "../components/SEO/SEO";
import PcisBanners from "../PCIS/components/PCIsBanner/PcisBanners";

const index = () => {
  // const ContactWithForm = withForm(Contact);
  return (
    <>
      <SEO title={"PCIs RAMÓN Y CAJAL ZARAGOZA"} description={""} robots={"index, follow"} />
      <HomeMarkupSchema />
      <Layout>
        <Hero title={"PCIs RAMÓN Y CAJAL ZARAGOZA"} background={photoHero} />
        <main>
          <section className={"container"}>
            <div style={{ maxWidth: "800px" }}>
              <p className={"h2"}>Bienvenidos al Programa de Cualificación Inicial del IES Ramón y Cajal</p>
              <p>
                Bienvenidas a todas las familias de jóvenes con necesidades educativas especiales de entre 16 y 21 años,
                personas interesadas y entidades que queréis conocer la formación profesional adaptada aragonesa.
              </p>
              <p>
                Aquí podéis encontrar toda la información sobre nuestros Programas de Cualificación Inicial modalidad II
                cuya finalidad es formar en un campo profesional a jóvenes con diversidad funcional para mejorar sus
                posibilidades de acceso al mundo laboral
              </p>
            </div>
            <div className={"o-europe"}>
              <StaticImage
                className={"a-europe__flag"}
                src={"../assets/images/bandera-union-europea-s.jpg"}
                alt={"bandera Unión Europe"}
                width={60}
              />
              <p style={{ fontSize: "0.85rem", opacity: "0.9" }}>
                El proyecto Inserta XXI está cofinanciado por el programa <span className={"uppercase"}>E</span>rasmus
                <sup>+</sup> de la Unión Europea. El contenido esta web es responsabilidad exclusiva del IES Ramón y
                Cajal de Zaragoza y ni la Comisión Europea, ni el Servicio Español para la Internacionalización de la
                Educación (SEPIE) son responsables del uso que pueda hacerse de la información aquí difundida.

              </p>
            </div>
            <PcisBanners />
            <section className={"grid-x2 grid--bottom grid--break-tablet"}>
              <div>
                <h2>¿Qué son los Programas de cualificación inicial?</h2>
                <p>
                  Un Programa de Cualificación Profesional Inicial modalidad II es una vía para personas sin
                  cualificación profesional y con necesidades educativas especiales.
                </p>
                <p>
                  El objetivo es desarrollar una actividad en un campo profesional, contribuir a su desarrollo personal
                  y formar parte de la sociedad como ciudadanos de pleno derecho.
                </p>
              </div>
              <div className={"is-relative"}>
                <div className={"video--bloque--a"}></div>
                <LazyIframe src={"Es5dTxh0pEg"} title={"PCI. 25 ANIVERSARIO. IES Ramón y Cajal, Zaragoza"} />
              </div>
            </section>
          </section>
          <section>
            <div className={"container"}>
              <h2>Cómo se estructura un PCI</h2>
            </div>
            <ScrollContainer className="scroll-container">
              <div className={"scroll-container__content"} style={{ marginTop: "1em" }}>
                <div className={"m-structure-block"}>
                  <h3>Estructura</h3>
                  <ul>
                    <li> 2 cursos educativos, de 1000 horas cada uno.</li>
                    <li>
                      30 horas semanales en horario de mañana, 20 de las cuales son de talleres de la especialidad y 10
                      de enseñanzas básicas (lengua, matemáticas…)
                    </li>
                    <li> 240 horas de prácticas durante el último trimestre del 2º curso</li>
                  </ul>
                </div>
                <div className={"m-structure-block"}>
                  <h3>Módulos por semanas</h3>
                  <ul>
                    <li>Módulos formativos generales: Desarrollo Personal y Social y Aprendizajes Instrumentales
                      (10 horas)
                    </li>
                    <li>Módulos específicos de cualificación (17 horas)</li>
                    <li>Módulo de Prevención y Formación Laboral (2 horas)</li>
                    <li>Tutoría (1 hora)</li>
                  </ul>
                </div>
                <div className={"m-structure-block"}>
                  <h3>Profesorado/alumnado</h3>
                  <ul>
                    <li> 10 alumno/as por grupo</li>
                    <li> 2 a 3 profesore/as por grupo</li>
                    <li> 10 profesore/as en el equipo</li>
                  </ul>
                </div>
              </div>
            </ScrollContainer>
          </section>
          <section className={"container grid-70-30 grid-x2 grid--bottom grid--break-tablet"}>
            <div>
              <h2>Condiciones de acceso a los programas </h2>
              <ol type={"a"}>
                <li>
                  Tener resolución del Servicio Provincial competente en materia educativa no universitaria de
                  necesidades educativas especiales
                </li>
                <li>Tener entre 16 años cumplidos en el año natural en curso y no superar los 21</li>
                <li>Estar escolarizado en centros ordinarios o de educación especial</li>
                <li>
                  Presentar un nivel de funcionamiento escolar y personal incompatible con la posibilidad de obtener el
                  título de Graduado en Formación Secundaria o alcanzar los objetivos de una Formación Profesional
                  Básica
                </li>
                <li>
                  Tener un nivel de funcionamiento escolar y personal suficiente para alcanzar las unidades de
                  competencia profesional del PCI elegido
                </li>
                <li>Su comportamiento no debe entrañar riesgos para su integridad física o la de sus compañeros</li>
                <li>No haber cursado con anterioridad dos o más programas de PCI</li>
              </ol>
            </div>

            {/*<a*/}
            {/*  className={"pdf-top"}*/}
            {/*  href={"/downloads/condiciones-acceso.pdf"}*/}
            {/*  rel="noopener noreferrer"*/}
            {/*  target={"_blank"}*/}
            {/*>*/}
              <img src={iconPdf} alt={"descargar condiciones"} width={"250"} height={"250"} />
            {/*  <span> Descargar documentación </span>*/}
            {/*</a>*/}
          </section>
          <Map>
            <h2>¿Necesitas más información sobre nuestros PCI?</h2>
            <p>
              Si tienes más dudas, envía un correo electrónico a la siguiente dirección{" "}
              <a href={"mailto:pci.ryc.zgz@gmail.com"} target={"_blank"} rel={"noopener noreferrer"}>
                {" "}
                pci.ryc.zgz@gmail.com{" "}
              </a>{" "}
              o rellena el siguiente formulario de información
            </p>
            {/*<ContactWithForm />*/}
          </Map>
        </main>
      </Layout>
    </>
  );
};

export default index;
