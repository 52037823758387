import * as React from "react";
import Helmet from "react-helmet";

import { contact, defaultTitle, legalName, logo, siteUrl } from "../../data/config";

const HomeMarkupSchema: React.FC = () => {
  const markup = `{
  "@context": "https://schema.org",
  "@type": "HighSchool",
  "name": "${legalName}",
  "telephone": "${contact.phone}",
  "email": "${contact.email}",
  "alternateName": "${defaultTitle}",
  "location": {
    "@type": "Place",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Zaragoza",
      "addressRegion": "Zaragoza",
      "postalCode": "50004",
      "streetAddress": "C/ Ramón Pignatelli, 102,"
    }
    },
  "url": "${siteUrl}",
  "logo": "${logo}",
  "knowsAbout":[
   {
    "@type": "Course",
    "name": "PCI Agrojardinería y Arreglos florales",
    "provider": {
      "@type": "HighSchool",
      "name": "PCI IES Ramón y Cajal",
      "url": "${siteUrl}agrojardineria-y-arreglos-florales/"
      }
    },
    {
    "@type": "Course",
    "name": "PCI Fabriación y montaje",
    "provider": {
      "@type": "HighSchool",
      "name": "PCI IES Ramón y Cajal",
      "url": "${siteUrl}fabricacion-y-montaje/"
      }
    },
    {
    "@type": "Course",
    "name": "PCI Lavandería y arreglos textiles",
    "provider": {
      "@type": "HighSchool",
      "name": "PCI IES Ramón y Cajal",
      "url": "${siteUrl}lavanderia-y-arreglos-textiles/"
      }
    }
  ]
}`;
  return (
    <Helmet>
      <script type="application/ld+json">{markup}</script>
    </Helmet>
  );
};

export default HomeMarkupSchema;
